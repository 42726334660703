/* import  */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;700&display=swap');

/* html, body */
html,
body {
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  height: 100%;
  width: 100%;
  background: black;
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* App container */
#AppCon{
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: black;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
   background-size: cover;
}

/* UPDATING-CODE */
#UPDATING-CODE {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  padding: 25px;
  z-index: 9999;
  display: none;
}

/* loader */
.loaderBG {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 11;
  display: block;
  transition: opacity .4s ease; 
}

/* loaderIMG */
.loaderIMG { 
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 100px;
  transform: translate(-50%, -50%);
  background: url("loader.gif") no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 500%;
}

/** Show **/
.Show { display: block; }

/* Hide */
.Hide { display: none; }

/* Opacity 1 */
.Opacity1 { opacity: 1; }

/* Opacity 0 */
.Opacity0 { opacity: 0; }

/* expandEl !important */
.expandEl {
  padding: 0px 0px;
}

/* @media  */
@media (max-width: 523px) {
  .expandEl {
    padding: 50px 0px;
  }
}

/* highLightedTxt !important */
#highLightedTxt {
  position: fixed;
  bottom: 90px;
  left: 0px;
  width: 100%;
  font-size: 1.5rem;
  padding: 20px 43px;
  padding-bottom: 43px;
  cursor: default;
  color: black;
}

/* @media */
@media (max-width: 1366px) {
  #highLightedTxt {
    position: relative;
    bottom: 90px;
    left: 0px;
    cursor: default;
    color: black;
    /**/
    width: 100%;
    font-size: 1.5rem;
    padding: 15px 23px;
    padding-bottom: 13px;
    margin-top: -100px;
    margin-bottom: -75px;
  }
}

/* @media */
@media (max-width: 1024px) {
  #highLightedTxt {
    position: relative;
    bottom: 90px;
    left: 0px;
    cursor: default;
    color: black;
    /**/
    width: 100%;
    font-size: 1.5rem;
    padding: 15px 23px;
    padding-bottom: 13px;
    margin-top: -75px;
    margin-bottom: -75px;
  }
}

/* @media */
@media (max-width: 768px) {
  #highLightedTxt {
    position: relative;
    bottom: 90px;
    left: 0px;
    cursor: default;
    color: black;
    /**/
    width: 85%;
    font-size: 18px;
    padding: 10px 23px;
    padding-bottom: 13px;
    margin-top: 0px;
    margin-bottom: -75px;
  }
}

/* // ABC // About Data style  */
#AboutDataCon {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  z-index: 9;
  display: none;
  background-color: white;
}

/* specialhighLightedTxtCon !important */
#specialhighLightedTxtCon {
  position: absolute;
  padding: 5% 3%;
  top: 15%;
  left: 5%;
  width: 63%; /*!important */
  /* transform: translate(-50%, -50%); */
  font-size: 1.5rem;
  cursor: default;
  color: black;
  padding: 20px;
  background-color: none; /*!important */
}

/* @media */
@media (max-width: 1366px) {
  #specialhighLightedTxtCon {
    top: 15%;
    width: 85%; /*!important */
  }
}

/* @media */
@media (max-width: 1024px) {
  #specialhighLightedTxtCon {
    top: 15%;
    width: 85%; /*!important */
  }
}

/* @media */
@media (max-width: 768px) {
  #specialhighLightedTxtCon {
    top: 10%;
    width: 82%; /*!important */
  }
}

/* specialhighLightedTxt */
.specialhighLightedTxt {
  width: 100%;
  font-size: 6vw;
  margin-bottom: 50px;
  padding: 0px 0px;
  cursor: default;
  color: black;
}

/* @media */
@media (max-width: 1024px) {
  .specialhighLightedTxt {
    font-size: 6vw;
  }
}

/* @media */
@media (max-width: 768px) {
  .specialhighLightedTxt {
    font-size: 7vw;
  }
}

/* spaceIt */
.spaceIt {
  font-weight: normal;
  line-height: normal;
  padding-left: 6px;
  padding-bottom: 10px;
}

/* @media */
@media (max-width: 1366px) {
  .spaceIt {
    font-size: 1.5rem;
  }
}

/* @media */
@media (max-width: 1024px) {
  .spaceIt {
    font-size: 1.5rem;
  }
}

/* @media */
@media (max-width: 768px) {
  .spaceIt {
    font-size: 20px;
    padding-left: 0px;
    padding-bottom: 20px;
  }
}

/* isCdVisible */
#isCdVisible {
  position: fixed;
  bottom: 90px;
  left: 0px;
  width: 100%;
  font-size: 1.5rem;
  padding: 20px 43px;
  padding-bottom: 43px;
  cursor: default;
  color: white;
  background-color: none;
  display: block;
}

/* @media */
@media (max-width: 1366px) {
  #isCdVisible {
    width: 90%;
    font-size: 1.5rem;
    padding: 15px 43px;
    padding-bottom: 18px;
  }
}

/* @media */
@media (max-width: 1024px) {
  #isCdVisible {
    width: 95%;
    font-size: 1.5rem;
    padding: 15px 43px;
    padding-bottom: 18px;
  }
}

/* @media */
@media (max-width: 768px) {
  #isCdVisible {
    font-size: 18px;
    padding: 10px 23px;
    padding-bottom: 13px;
    display: none;
  }
}

/* brandblack !important */
.brandblack { 
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 20px 43px;
  list-style-type: none !important; cursor: default;
  color: black;
  font-size: 1.5rem;
  z-index: 10;
}

/* @media */
@media (max-width: 1366px) {
  .brandblack {
    position: relative;
    bottom: 0px;
    left: 0px;
    /**/
    font-size: 1.5rem;
    padding: 15px 23px;
    padding-bottom: 18px;
  }
}

/* @media */
@media (max-width: 1024px) {
  .brandblack {
    position: relative;
    bottom: 0px;
    left: 0px;
    /**/
    font-size: 1.5rem;
    padding: 15px 23px;
    padding-bottom: 18px;
  }
}

/* @media */
@media (max-width: 768px) {
  .brandblack {
    position: relative;
    bottom: 0px;
    left: 0px;
    /**/
    font-size: 18px;
    padding: 10px 23px;
    padding-bottom: 13px;
  }
}

/* specialbrandblack */
.specialbrandblack {
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 20px 43px;
  list-style-type: none !important; cursor: default;
  color: black;
  font-size: 1.5rem;
  z-index: 10;
}

/* @media */
@media (max-width: 1366px) {
  .specialbrandblack {
    font-size: 1.5rem;
    padding: 15px 43px;
    padding-bottom: 18px;
  }
}

/* @media */
@media (max-width: 1024px) {
  .specialbrandblack {
    font-size: 1.5rem;
    padding: 15px 43px;
    padding-bottom: 18px;
  }
}

/* @media */
@media (max-width: 768px) {
  .specialbrandblack {
    font-size: 18px;
    padding: 10px 23px;
    padding-bottom: 13px;
  }
}

/* // CD // video & image.png !important design 1 */
#ClientDataMockUps {
  overflow-x: hidden;
  position: absolute;
  top: 0px;
  padding: 10% 0px;
  z-index: 9;
  display: none;
}

/* // CD // just image.png & no video !important design 2 */
#ClientDataNoVidMockUps {
  overflow-x: hidden;
  position: absolute;
  top: 0px;
  padding: 10% 0px;
  z-index: 9;
  display: none;
}

/* // CD // image.png & video !important design 3 */
#ClientData3DMockUps {
  overflow-x: hidden;
  position: absolute;
  top: 0px;
  padding: 0% 0px;
  background-color: none;
  z-index: 9;
  display: none;
}

/* @media  */
@media (max-width: 768px) {
  #ClientDataMockUps, #ClientDataNoVidMockUps {
    font-size: 15px;
    padding-top: 10%;
    padding-bottom: 0%;
  }
}

/* @media  */
@media (max-width: 768px) {
  #ClientData3DMockUps {
    font-size: 15px;
    padding-top: 0%;
    padding-bottom: 0%;
  }
}

/* closeData !important */
.closeData:hover { 
  cursor: pointer; 
}

/* closeData */
.closeData { 
  position: fixed;
  top: 30px;
  left: 42px;
  width: 30px;
  height: 30px;
  background: url("closeData.png") no-repeat center center; 
  background-color: none;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100%;
}

/* @media  */
@media (max-width: 1366px) {
  .closeData {
    top: 20px;
    left: 42px;
    width: 30px;
    height: 30px;
  }
}

/* @media  */
@media (max-width: 1024px) {
  .closeData {
    top: 20px;
    left: 42px;
    width: 30px;
    height: 30px;
  }
}

/* @media  */
@media (max-width: 768px) {
  .closeData {
    top: 20px;
    left: 13px;
    width: 30px;
    height: 30px;
  }
}

.inner { 
  border-bottom: 12px solid white; 
  box-sizing: border-box; 
} 

/* cd3DImg */
#cd3DImg  {
  /* width: 100%; */
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}

#cd3DVid {
  max-width: 1100px;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 200px;
}

/* @media  */
@media (max-width: 1180px) {
  #cd3DVid {
    max-width: 900px;
    padding-top: 50px;
    padding-bottom: 200px;
  }
}

/* @media  */
@media (max-width: 1024px) {
  #cd3DVid {
    max-width: 800px;
    padding-top: 50px;
    padding-bottom: 200px;
  }
}

/* @media  */
@media (max-width: 768px) {
  #cd3DVid {
    max-width: 700px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* @media  */
@media (max-width: 432px) {
  #cd3DVid {
    max-width: 365px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

/* 
   cdVid mock
*/

/* cdVid */
#cdVid  {
  max-width: 1100px;
  width: 100%;
}

/* @media  */
@media (max-width: 1024px) {
  #cdVid  {
    max-width: 900px;
    width: 100%;
  }
}

/* @media  */
@media (max-width: 768px) {
  #cdVid  {
    /* max-width: 1000px;
    width: 100%; */
    object-fit: cover;
    width: 100vw;
    height: 100vh;
  }
}

/* 
   cdImg mock
*/

/* cdImg */
#cdImg  {
  max-width: 1100px;
  width: 100%;
}

/* @media  */
@media (max-width: 1024px) {
  #cdImg  {
    max-width: 900px;
    width: 100%;
  }
}

/* @media  */
@media (max-width: 768px) {
  #cdImg {
    max-width: 1000px;
    width: 100%;
  }
}

/* cImgB  */
.cImgB {
  display: none;
}

/* @media  */
@media (max-width: 768px) {
  .cImgB   {
    position: relative;
    width: 100%;
    height: 100px;
    background-color: none;
    display: block;
  }
}

/* CDMockUpsBG !important */
#CDMockUpsBG {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 8;
  display: none;
}

/* logo */
.logo {
  position: fixed;
  top: 20px;
  left: 33px;
  z-index: 7;
  /**/
  width: 50px;
  height: 50px;
  background: url("logoWHT.png") no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100%;
  display: block;
}

/* @media */
@media (max-width: 1024px) { 
  .logo {
    top: 20px; 
    left: 13px;
  }
}

/* @media */
@media (max-width: 768px) { 
  .logo {
    width: 45px;
    height: 45px;
  }
}

/* font style *//* font style *//* font style */
ul {
  position: absolute;
  top: 0%;
  right: 0%;
  padding: 5% 3%;
  list-style-type: none !important;
  z-index: 6;
  display: block;
}

/* @media */
@media (max-width: 1024px) {
  ul {right: 3%;}
}

/* li */
li { list-style-type: none !important; text-decoration: none; }

/* fs hover */
.fs:hover {
  cursor: pointer;
  color: white;
  opacity: 1;
}

/* fs */
.fs {
  position: relative;
  color: white;
  opacity: 0.5;
  font-size: 6vw;
  line-height: 6vw;
  font-weight: 900;
  text-transform: uppercase;
  text-align: right;
  letter-spacing: 0px;
  list-style-type: none;
  padding-bottom: 7%;
  background-color: none;
}

/* @media */
@media (max-width: 1366px) {
  .fs {font-size: 6vw; line-height: 11vw;}
}

/* @media */
@media (max-width: 1024px) {
  .fs {font-size: 6vw; line-height: 11vw;}
}

/* @media */
@media (max-width: 768px) {
  .fs {font-size: 13vw; line-height: 23vw;}
}

/* brand *//* brand *//* brand */
.brand { 
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 20px 43px;
  color: white;
  font-size: 1.5rem;
  z-index: 5;
}

/* @media */
@media (max-width: 1366px) {
  .brand {
    font-size: 1.5rem;
    padding: 15px 43px;
    padding-bottom: 18px;
  }
}

/* @media */
@media (max-width: 1024px) {
  .brand {
    font-size: 1.5rem;
    padding: 15px 43px;
    padding-bottom: 18px;
  }
}

/* @media */
@media (max-width: 768px) {
  .brand {
    font-size: 18px;
    padding: 10px 23px;
    padding-bottom: 13px;
  }
}

/* brandTxt */
#brandTxt {
  cursor: default;
}

/* span */
#span { 
  font-size: 23px;/* very -> !important */
  padding: 0px 30px;/* !important */
  cursor: default;
}

/* @media */
@media (max-width: 768px) {
  #brandTxt, #span {
    display: none;
  }
}

/* mouseOverAboutBtn:hover */
#mouseOverAboutBtn:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.36);
}

/* mouseOverAboutBtn */
#mouseOverAboutBtn {
  color: white;
}

/* Switch Client Data In Component === {ID} // CD // path/to/image1.jpg */
#ClientData {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  padding: 0px;
  background: none;
  z-index: 4;
  display: block;
}

/* fullscreen imgBG  */
.imgBG, #imgBG {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black; opacity: .5;
  background-color: none;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
   background-size: cover;
   z-index: 2;
   display: block;
}

/* useRef // all mouse overs // for background hovers */
.bgImg3D1 {background: url("batman-fulsrcn.png") no-repeat center center;}

.bgImg1 {background: url("cinemaui-wbg.png") no-repeat center center;}

.bgImg2 {background: url("touristsui-ybg.png") no-repeat center center;}

.bgImg3 {background: url("coke.png") no-repeat center center;}

.bgImg4 {background: url("pbs.png") no-repeat center center;}

.bgImg5 {background: url("av.jpg") no-repeat center center;}

.bgImg6 {background: url("corpay.png") no-repeat center center;}

.bgImg7 {background: url("fitness-bbg.png") no-repeat center center;}

.bgImg8 {background: url("productiv.png") no-repeat center center;}

.bgImg9 {background: url("americanv.png") no-repeat center center;}

.bgImg10 {background: url("milliken.png") no-repeat center center;}

/* fullsccreen videoBG */
#vidBG {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: .5;
  z-index: 1;
  display: block;
}